<template>
  <div>
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div class="col">
          <div class="toolbar-title">
            <el-form-item label="状态">
              <el-select v-model="params.status" @change="handleSearch">
                <el-option label="全部" value=""></el-option>
                <el-option label="启用中" value="1"></el-option>
                <el-option label="已禁用" value="0"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="col-auto">
          <el-button size="small" type="primary" @click="handleAdd">
            添加销售人员
          </el-button>
        </div>
      </template>
      <template slot="table-columns">
        <el-table-column prop="sn" label="编号" width="120"></el-table-column>
        <el-table-column prop="name" label="销售人员名称" min-width="200" />
        <el-table-column prop="mobile" label="手机号" min-width="120" />
        <el-table-column prop="create_time" label="创建时间" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{ ['已禁用', '启用中'][scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEditShopAssistant(scope.$index, scope.row)">编辑
            </el-button>
            <el-button size="mini" type="danger" v-if="scope.row.status === 1"
              @click="handleDeleteShopAssistant(scope.row, 0)">禁用
            </el-button>
            <el-button size="mini" type="success" v-if="scope.row.status === 0"
              @click="handleRecoveryShopAssistant(scope.row, 1)">启用
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" :page-size="tableData.page_size" layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <!--编辑-->
    <el-dialog :title="['添加', '编辑'][+isEdit]" :visible.sync="editDialogVisible" width="500px"
      :close-on-click-modal="false" :close-on-press-escape="false" destroy-on-close>
      <el-form :model="saleForm" :rules="shopAssistantRules" ref="saleFormRef" label-width="100px">
        <el-form-item label="用户名" prop="name">
          <el-input v-model="saleForm.name" :maxlength="20" clearable placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="saleForm.mobile" :maxlength="11" clearable autocomplete="off"
            placeholder="请输入手机号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="editForm" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { RegExp } from "@/../ui-utils";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";
import { getSalesMan, changeSalesManStatus, addSalesMan, editSaleMan } from "@/api/sales";
import * as API_Order from "@/api/order";
export default {
  name: "shopAssistant",
  components: {
    EnTableLayout,
  },
  data () {
    return {
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        status: "",
      },
      tableData: { data: [] },
      shopAssistantPhoneForm: {},
      shopAssistantPhoneRules: {
        mobile: [
          this.MixinRequired("请输入手机号码！"),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error("手机号码格式有误！"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      saleForm: {
        name: "",
        mobile: ""
      },
      shopAssistantRules: {
        name: [
          { required: true, message: "请输入用户名！", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value && !value.trim()) {
                callback(new Error("请输入用户名,且不能为空格！"));
              }
              // else if (!/^[0-9A-Za-z]{6,20}$/.test(value)) {
              //   // /^(?![0-9]+$)[\u4e00-\u9fa5_0-9A-Za-z]{6,20}$/
              //   callback(
              //     new Error('用户名名称不可为汉字，长度6-20个字符，不允许特殊符号！')
              //   );
              // }
              else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        mobile: [
          this.MixinRequired("请输入手机号码！"),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error("手机号码格式有误！"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      dialogVisible: false,
      editDialogVisible: false,
      isEdit: false,
    };
  },
  watch: {},
  mounted () {
    API_Order.getShopExtAuth().then(res => {
      if (res.open === 'OPEN') {

      } else {
        this.$message.error('平台已关闭销售开卡功能，如有其他问题，请联系平台。');
        setTimeout(() => {
          this.$router.replace({ path: '/' }).catch(() => { })
        }, 2000)
      }
    })
    this.getList();
  },
  activated () { },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.getList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getList();
    },
    async handleAdd () {
      this.editDialogVisible = true;
      this.saleForm = {};
      this.isEdit = false;
    },
    handleEditShopAssistant (index, row) {
      this.saleForm = {
        ...row,
      };
      this.editDialogVisible = true;
      this.isEdit = true;
    },
    handleDeleteShopAssistant (row, status) {
      this.$confirm("确定要禁用当前的销售人员吗？", "提示", { type: "warning" })
        .then(() => {
          changeSalesManStatus(row.id, status).then(() => {
            this.$message.success("禁用成功");
            this.getList();
          });
        })
        .catch(() => { });
    },
    handleRecoveryShopAssistant (row, status) {
      this.$confirm("确定要启用当前的销售人员吗？", "提示", { type: "warning" })
        .then(() => {
          changeSalesManStatus(row.id, status).then(() => {
            this.$message.success("启用成功");
            this.getList();
          });
        })
        .catch(() => { });
    },
    editForm () {
      this.$refs.saleFormRef.validate((valid) => {
        if (valid) {
          let EDIT_API = [addSalesMan, editSaleMan][+this.isEdit];
          EDIT_API(this.saleForm).then(res => {
            console.log(res)
            this.editDialogVisible = false;
            this.saleForm = {
              name: "",
              mobile: ""
            }
            this.getList();
          })
        } else {
          this.$message.error("表单填写有误，请核对！");
          return false;
        }
      });
    },
    getList () {
      this.loading = true;
      getSalesMan(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSearch () {
      this.params.page_no = 1;
      this.getList();
    }
  },
};
</script>
<style lang="scss" scoped>
.face-image {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

/deep/ .el-dialog__body {
  padding: 10px 20px;
}

/deep/ .img-code {
  width: 80%;

  .el-input-group__append {
    padding: 0;
    margin: 0;

    img {
      display: block;
      height: 30px;
      cursor: pointer;
    }
  }
}
</style>
